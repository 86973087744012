import React from 'react';
import { TFunction, useTranslation, TransProps } from '@wix/yoshi-flow-editor';

import uri from '@wix/communities-forum-client-commons/dist/src/services/urijs';

// eslint-disable-next-line
const NUMBER_REGEX = /(\d+(\.?,? ?\d*)*)/g; // the "space" symbol in this regex is special, don't modify

const showKeys =
  typeof window !== 'undefined' &&
  uri(window.location, undefined).hasQuery('debug-translate', 'true');

export const useTranslate = (
  ns?: Parameters<typeof useTranslation>[0],
  options?: Parameters<typeof useTranslation>[1],
): { t: TFunction; i18n: Required<TransProps<string>['i18n']> } => {
  const [t, i18n] = useTranslation(ns, options);
  const translations = i18n.store.data[i18n.language].translation;
  let theT;
  theT = React.useCallback(
    (key: string, ...args: any) => {
      const value: string = (translations as { [key: string]: string })[key];
      const translated = t(key, ...args);

      // transform numbers in plurals to compact ones, as ICU doesn't have this out of the box
      if (value && value.includes('plural') && value.includes('{count')) {
        const unformattedNumber = args[0].count;
        if (unformattedNumber) {
          return translated.replace(NUMBER_REGEX, t('number', { count: unformattedNumber }));
        }
      }

      return translated;
    },
    [t],
  );

  if (showKeys) {
    theT = (key: string) => key;
  }

  return { t: theT, i18n };
};
