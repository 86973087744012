import React from 'react';
import { createContext } from '../../api-providers/use-context-selector';

export const AllStateCtx = createContext({
  state: undefined,
  actions: undefined,
  host: undefined,
  whenWorkerInitialized: undefined,
});

export default AllStateCtx;
